import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ConfigProvider } from 'antd';
import es_ES from 'antd/es/locale/es_ES';
import './styles/index.less';
import './assets/fonts/index.less';

ReactDOM.render(
  <ConfigProvider locale={es_ES}>
    <App />
  </ConfigProvider>
  ,
  document.getElementById('root')
);

