import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { Empty, Image } from 'antd';
import styled from "styled-components";
import { imgFallback } from "../constants/utils";

const ImageViewer = ({ data }) => {

    if (!data) { return <EmptyWrapper /> }

    return (
        <ImageViewerWrapper>

            {
                data.map(
                    img => <Image key={uuidv4()}
                        src={img.content} fallback={imgFallback} />)
            }
        </ImageViewerWrapper>
    );
};
export default ImageViewer;

const EmptyWrapper = styled(Empty)`
    margin-top: 40px;
`;

const ImageViewerWrapper = styled.div`
    padding: 20px;
    max-width: 800px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    & > * {
        width: 100%;
        margin: auto;
    }

    @media screen and (max-width: 840px) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media screen and (max-width: 580px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 380px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;