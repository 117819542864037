import { useEffect, useState } from 'react';
import ImageViewer from './components/ImageViewer';

// eslint-disable-next-line no-unused-vars
const mock = {
  type: 'imageLoad',
  data: [
    {
      type: 'image',
      content: 'http://picsum.photos/500/500',
      description: 'TEST'
    },
    {
      type: 'image',
      content: 'base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4QDeRXh...',
      description: 'TEST'
    },
  ]
};



const App = () => {

  const [data, setData] = useState();

  const onMessage = msg => {
    if (msg.data?.type === 'imageLoad') {
      setData(msg.data?.data);
    }
  };

  useEffect(() => {
    window.addEventListener('message', onMessage);
  }, [])

  return (
    <div className="App">
      <ImageViewer data={data} />
    </div>
  );
}

export default App;




















